<template>
  <div class="container">
    <div class="section">
      <div class="title">
        <el-image
          class="title_img"
          :src="require('@as/event/create/icon_1.png')"
        ></el-image>
        <div>基本信息</div>
      </div>
      <div class="main">
        <div class="form_item_title">
          <div>活动名称</div>
          <div class="mark">*</div>
        </div>
        <div class="form_item_input">
          <el-input
            v-model="title"
            placeholder="例如：第三届中国互联网大会，字数不少于5个字，最多30个字。"
            maxlength="30"
            minlength="5"
          ></el-input>
        </div>
        <div class="from_item_time" style="margin-right: 12px">
          <div class="form_item_title">
            <div>开始时间</div>
            <div class="mark">*</div>
          </div>
          <el-date-picker
            v-model="startTime"
            type="datetime"
            placeholder="选择日期时间"
            prefix-icon="none"
            :clearable="false"
          >
          </el-date-picker>
          <el-image
            class="time_img"
            :src="require('@as/event/create/icon_2.png')"
          ></el-image>
        </div>
        <div class="from_item_time">
          <div class="form_item_title">
            <div>结束时间</div>
            <div class="mark">*</div>
          </div>
          <el-date-picker
            v-model="entTime"
            type="datetime"
            placeholder="选择日期时间"
            prefix-icon="none"
            :clearable="false"
          >
          </el-date-picker>
          <el-image
            class="time_img"
            :src="require('@as/event/create/icon_2.png')"
          ></el-image>
        </div>
        <div class="form_item_title">
          <div>活动地点</div>
          <div class="mark">*</div>
        </div>
        <div class="from_item_select">
          <el-select v-model="province" placeholder="省份/直辖市">
            <el-option
              v-for="item in provinceList"
              :key="item.provinceID"
              :label="item.province"
              :value="item.provinceID"
            >
            </el-option>
          </el-select>
          <el-select v-model="area" placeholder="城市/地区">
            <el-option
              v-for="item in cityList"
              :key="item.cityID"
              :label="item.city"
              :value="item.cityID"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="detailed"
            placeholder="请先选择省市，然后输入详细地址"
          ></el-input>
          <el-image
            class="address_img"
            :src="require('@as/event/create/icon_3.png')"
          ></el-image>
        </div>
        <div class="online_event">
          <el-image
            class="online_img"
            :src="require('@as/event/create/icon_4.png')"
          ></el-image>
          <div>这是线上活动</div>
        </div>
        <div v-for="(item, index) in guestList" :key="index">
          <div class="form_item_title">
            <div>
              {{
                index === '1' ? '主办方' : index === '2' ? '协办方' : '活动嘉宾'
              }}
            </div>
            <div class="mark">*</div>
          </div>
          <div class="form_item_select">
            <el-select
              v-model="sponsorId"
              multiple
              :placeholder="
                index === '1' ? '主办方' : index === '2' ? '协办方' : '活动嘉宾'
              "
            >
              <el-option
                v-for="i in item"
                :key="i.id"
                :label="i.name"
                :value="i.id"
              >
              </el-option>
            </el-select>
            <div class="addOption" @click="handlerClickAddGuest(index)">
              <el-image
                class="img"
                :src="require('@as/event/create/icon_10.png')"
              ></el-image>
              <div>
                添加{{
                  index === '1'
                    ? '主办方'
                    : index === '2'
                    ? '协办方'
                    : '活动嘉宾'
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="form_item_title">
          <div>活动海报</div>
          <div class="mark">*</div>
        </div>
        <div class="banner">
          <div class="left" v-if="!fileImg">
            <el-image
              class="img"
              :src="require('@as/event/create/banner_img.png')"
            ></el-image>
            <div>请上传尺寸为438px*211px的png、jpg、gif图片 图片大小小于1M</div>
          </div>
          <div
            class="left"
            :style="`background-image: url(${fileImg});`"
            v-else
          ></div>
          <div class="right">
            <div class="btn">
              <el-image
                class="img"
                :src="require('@as/event/create/icon_6.png')"
              ></el-image>
              <el-upload
                action=""
                :multiple="false"
                accept=".png,.jpg,.gif"
                :show-file-list="false"
                :before-upload="handlerUpload"
              >
                <div>上传图片</div></el-upload
              >
            </div>
            <div class="tips">
              <p>温馨提示：</p>
              <p>
                此图片将作为此活动的封面，一张漂亮的封面图片可以提高浏览者视觉体验，同时可以宣传活动内容。
              </p>
              <p>
                如果您并没有合适的图片，可以在系统图库中选择一张图片。建议上传尺寸。
              </p>
              <p>
                建议上传尺寸为<span style="color: #c40012">438px*211px</span>
                的png、jpg、gif图片，图片大小小于1M。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="title">
        <el-image
          class="title_img"
          :src="require('@as/event/create/icon_7.png')"
        ></el-image>
        <div>活动详情</div>
        <div class="mark">*</div>
        <div class="tag">
          <div
            class="item"
            :class="{ active: item.select }"
            v-for="(item, index) in detailsList"
            :key="index"
            @click="handlerSelect(index)"
          >
            <div>{{ item.name }}</div>
            <i class="el-icon-close icon" v-if="item.select"></i>
          </div>
        </div>
      </div>
      <div class="main">
        <div class="editor" v-for="item in detailsList" :key="item.name">
          <quill-editor
            v-model="item.value"
            ref="myQuillEditor"
            :options="editorOption(item.name)"
            v-show="item.select"
          >
          </quill-editor>
        </div>
        <div class="form_item_title">
          <div>活动标签</div>
          <div class="mark">*</div>
        </div>
        <div class="tag_container">
          <div class="tag_gorup">
            <div class="item" v-for="(item, index) in tagList" :key="index">
              {{ item }}
            </div>
            <div
              class="add"
              @click="() => (this.showTagPanel = !this.showTagPanel)"
            >
              <i>+</i>
              <div>添加标签</div>
            </div>
          </div>
          <div class="text">最多添加{{ 6 - tagList.length }}个</div>
          <div class="float_tag_container" v-if="showTagPanel">
            <div class="container_title">
              还可以添加{{ 6 - tagList.length }}个标签
            </div>
            <el-menu
              :default-active="tagSelect"
              class="el-menu-demo"
              mode="horizontal"
              @select="(tagSelect) => (this.tagSelect = tagSelect)"
            >
              <el-menu-item
                :index="index"
                v-for="(item, index) in tagsList"
                :key="index"
                >{{ index }}</el-menu-item
              >
            </el-menu>
            <ul class="infinite-list" style="overflow: auto">
              <li
                v-for="i in tagDetailList"
                class="infinite-list-item"
                :key="i"
                @click="handlerAddTag(i)"
              >
                {{ i }}
              </li>
            </ul>
          </div>
        </div>
        <div class="tips">
          选择合适的分类标签有助于用户在活动检索中发现您的活动，从而提高活动报名数量
        </div>
        <div class="last">
          <div class="last_title">活动隐私</div>
          <div class="switch">
            <div
              class="item"
              :class="{ active: !isPrivate }"
              @click="() => (this.isPrivate = !this.isPrivate)"
            >
              {{ isPrivate ? '私有' : '公开' }}
            </div>
          </div>
          <div class="save_btn">保存活动基本信息</div>
        </div>
      </div>
    </div>
    <div class="section" v-if="false">
      <div class="title">
        <el-image
          class="title_img"
          :src="require('@as/event/create/icon_11.png')"
        ></el-image>
        <div>门票设置</div>
      </div>
      <div class="main">
        <div class="apply_container">
          <div class="row">
            <div class="label">报名通道</div>
            <div class="switch">
              <div class="item active">公开</div>
            </div>
          </div>
          <div class="row">
            <div class="label">
              <div>活动人数</div>
              <div class="mark">*</div>
            </div>
            <el-input placeholder="1000"></el-input>
          </div>
          <div class="row">
            <div class="label">
              <div>活动收费</div>
              <div class="mark">*</div>
            </div>
            <el-select v-model="province" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="title">
        <el-image
          class="title_img"
          :src="require('@as/event/create/icon_8.png')"
        ></el-image>
        <div>报名表单</div>
      </div>
      <div class="main">
        <div class="form_container">
          <div class="form_row">
            <el-image
              class="form_row_icon"
              :src="require('@as/event/create/icon_5.png')"
            ></el-image>
            <!-- <el-checkbox>必填</el-checkbox> -->
            <div class="form_row_tip">必填</div>
            <el-input placeholder="姓名"></el-input>
          </div>
          <div class="form_row">
            <el-image
              class="form_row_icon"
              :src="require('@as/event/create/icon_5.png')"
            ></el-image>
            <!-- <el-checkbox>必填</el-checkbox> -->
            <div class="form_row_tip">必填</div>
            <div>
              <el-input placeholder="邮箱"></el-input>
              <div class="tips">
                <div>是否需要发送邮件验证邮箱账号真实性</div>
                <el-radio-group text-color="#666666" fill="#595756">
                  <el-radio :label="1">需要</el-radio>
                  <el-radio :label="2">不需要</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="form_row">
            <el-image
              class="form_row_icon"
              :src="require('@as/event/create/icon_5.png')"
            ></el-image>
            <!-- <el-checkbox>必填</el-checkbox> -->
            <div class="form_row_tip">必填</div>
            <div>
              <el-input placeholder="手机号"></el-input>
              <div class="tips">
                <div>是否需要发送短信验证手机号真实性</div>
                <el-radio-group text-color="#666666" fill="#595756">
                  <el-radio :label="1">需要</el-radio>
                  <el-radio :label="2">不需要</el-radio>
                </el-radio-group>
              </div>
            </div>
          </div>
          <div class="form_row">
            <el-image
              class="form_row_icon"
              :src="require('@as/event/create/icon_5.png')"
            ></el-image>
            <!-- <el-checkbox>必填</el-checkbox> -->
            <div class="form_row_tip">必填</div>
            <el-input placeholder="单位"></el-input>
          </div>
          <div class="form_row">
            <el-image
              class="form_row_icon"
              :src="require('@as/event/create/icon_5.png')"
            ></el-image>
            <!-- <el-checkbox>必填</el-checkbox> -->
            <div class="form_row_tip">必填</div>
            <el-input placeholder="职位"></el-input>
          </div>
        </div>
      </div>
    </div>

    <div class="footer_btn">
      <div class="btn" @click="handlerAdd(0)">存草稿</div>
      <div class="btn" @click="handlerAdd(1)">发布</div>
    </div>
    <div class="footer_read">
      <el-checkbox> 已阅读并同意 <span>《活动行服务协议》</span> </el-checkbox>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="930px"
      :before-close="handleClose"
    >
      <div class="create_container">
        <div class="row">
          <div class="label">
            {{
              guestInfo.events === 1
                ? '主办方图标'
                : guestInfo.events === 2
                ? '协办方图标'
                : '嘉宾头像'
            }}
          </div>
          <div class="mark">*</div>
          <el-image class="create_img" :src="guestInfo.icon"></el-image>
          <div class="info">
            <el-upload
              action=""
              :show-file-list="false"
              accept=".jpg,.gif,.png,.bmp"
              :before-upload="handlerUpload"
            >
              <div class="btn">
                <el-image
                  class="btn_icon"
                  :src="require('@as/event/create/icon_9.png')"
                ></el-image>
                <div>上传</div>
              </div></el-upload
            >
            <div class="tips">
              图片小于2M（ jpg、gif、png、bmp）推荐尺寸360*360px的图片！
            </div>
          </div>
        </div>
        <div class="row">
          <div class="label">
            {{
              guestInfo.events === 1
                ? '主办方名称'
                : guestInfo.events === 2
                ? '协办方名称'
                : '嘉宾姓名'
            }}
          </div>
          <div class="mark">*</div>
          <el-input
            placeholder="限制50个字以内"
            v-model="guestInfo.name"
            maxlength="50"
          ></el-input>
        </div>
        <div class="row" v-if="guestInfo.events !== 3">
          <div class="label">联系人</div>
          <div class="mark">*</div>
          <el-input
            placeholder="限制30个字以内"
            v-model="guestInfo.conName"
            maxlength="30"
          ></el-input>
        </div>
        <div class="row" v-else>
          <div class="label">职位/头衔</div>
          <div class="mark">*</div>
          <el-input
            placeholder="限制30个字以内"
            v-model="guestInfo.job"
            maxlength="30"
          ></el-input>
        </div>
        <div class="row" v-if="guestInfo.events !== 3">
          <div class="label">联系人手机</div>
          <div class="mark">*</div>
          <el-input
            placeholder="请输入真实手机号"
            v-model="guestInfo.conMobile"
            maxlength="11"
            type="number"
          ></el-input>
        </div>
        <div class="row" v-if="guestInfo.events !== 3">
          <div class="label">联系人邮箱</div>
          <div class="mark">*</div>
          <el-input
            placeholder="请输入真实有效邮箱"
            v-model="guestInfo.conEmail"
          ></el-input>
        </div>
        <div class="row" v-else>
          <div class="label">所在公司/组织</div>
          <div class="mark">*</div>
          <el-input
            placeholder="限制30个字以内"
            v-model="guestInfo.organize"
            maxlength="30"
          ></el-input>
        </div>
        <div class="row">
          <div class="label">
            {{
              guestInfo.events === 1
                ? '主办方'
                : guestInfo.events === 2
                ? '协办方'
                : '嘉宾'
            }}简介
          </div>
          <div class="mark">*</div>
          <el-input
            type="textarea"
            :rows="4"
            placeholder="请输入相关简介"
            v-model="guestInfo.descript"
          >
          </el-input>
        </div>
        <div class="row">
          <div class="label">添加Hui律网个人主页</div>
          <div class="pre">http：//</div>
          <el-input placeholder="" v-model="guestInfo.website"></el-input>
          <div class="create_btn">如果没有点击邀请创建</div>
        </div>
        <div class="btn_group">
          <div class="btn" @click="handlerAddPeople">确定</div>
          <!-- <div class="btn">另存草稿</div> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { Activity, Basic } from '@api'
export default {
  data() {
    return {
      dialogVisible: false,
      showTagPanel: false,
      title: '',
      startTime: '',
      entTime: '',
      province: '',
      area: '',
      detailed: '',
      sponsorId: [],
      cosponsorId: [],
      guestId: [],
      fileImg: '',
      file: {},
      detailsList: [
        {
          keys: 'ground',
          name: '活动背景',
          select: true,
          value: '',
        },
        {
          keys: 'content',
          name: '活动内容',
          select: false,
          value: '',
        },
        {
          keys: 'topics',
          name: '核心议题',
          select: false,
          value: '',
        },
        {
          keys: 'schedule',
          name: '活动日程',
          select: false,
          value: '',
        },
        {
          keys: 'seek',
          name: '活动咨询',
          select: false,
          value: '',
        },
      ],
      isPrivate: false,
      provinceList: [],
      cityList: [],
      tagSelect: '',
      tagsList: {},
      guestList: {
        1: [],
        2: [],
        3: [],
      },
      tagList: [],
      guestInfo: {
        events: 1,
        icon: require('@as/event/create/icon_5.png'),
        iconFile: {},
        name: '',
        conName: '',
        job: '',
        conMobile: '',
        conEmail: '',
        organize: '',
        descript: '',
        website: '',
      },
    }
  },
  components: {
    quillEditor,
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill
    },
    tagDetailList() {
      return this.tagsList[this.tagSelect]
    },
  },
  watch: {
    province(val) {
      if (!val) return
      Basic.getCity({
        provinceID: val,
      }).then((res) => {
        this.cityList = res
      })
    },
  },
  created() {
    // 获取省
    Basic.getProvince().then((res) => {
      this.provinceList = res
    })
    // 获取活动标签
    Activity.getTagList().then((res) => {
      this.tagsList = res
      this.tagSelect = Object.keys(res)[0]
    })
    // 获取人员列表
    for (let i = 1; i < 4; i++) {
      this.getGuestList(i)
    }
  },
  methods: {
    editorOption(name) {
      return {
        placeholder: `请输入${name}`,
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'], //加粗，斜体，下划线，删除线
            ['blockquote', 'code-block'], //引用，代码块
            [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
            [{ list: 'ordered' }, { list: 'bullet' }], //列表
            [{ script: 'sub' }, { script: 'super' }], // 上下标
            [{ indent: '-1' }, { indent: '+1' }], // 缩进
            [{ direction: 'rtl' }], // 文本方向
            [{ size: ['small', false, 'large', 'huge'] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
            [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
            [{ font: [] }], //字体
            [{ align: [] }], //对齐方式
            ['clean'], //清除字体样式
            ['image', 'video'], //上传图片、上传视频
          ],
        },
      }
    },
    handlerAddTag(event) {
      if (this.tagList.length === 6) return
      this.tagList.push(event)
    },
    handlerUpload(file) {
      let isToBig = this.dialogVisible
        ? file.size / 1024 / 1024 > 2
        : file.size / 1024 / 1024 > 1
      if (isToBig) {
        this.$message.error(`请上传${this.dialogVisible ? 2 : 1}M以内的图片`)
        return false
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      if (!this.dialogVisible) {
        this.file = file
        reader.onloadend = (e) => {
          this.fileImg = e.target.result
        }
      } else {
        this.guestInfo.iconFile = file
        reader.onloadend = (e) => {
          this.guestInfo.icon = e.target.result
        }
      }
      return false
    },
    handlerSelect(index) {
      const { select } = this.detailsList[index]
      if (select) this.detailsList[index].value = ''
      this.detailsList[index].select = !select
    },
    handleClose(done) {
      this.guestInfo = {
        events: 1,
        icon: require('@as/event/create/icon_5.png'),
        iconFile: {},
        name: '',
        conName: '',
        job: '',
        conMobile: '',
        conEmail: '',
        organize: '',
        descript: '',
        website: '',
      }
      done()
    },
    async handlerAdd(status) {
      const data = {
        title: this.title,
        startTime: this.startTime,
        entTime: this.entTime,
        province: this.province,
        area: this.area,
        detailed: this.detailed,
        sponsorId: this.sponsorId,
        guestId: this.guestId,
        fileImg: this.fileImg,
        tagList: this.tagList,
        isPrivate: this.isPrivate,
        status,
        userId: this.$store.state.uid,
      }
      const validDir = {
        title: '活动名称',
        startTime: '开始时间',
        entTime: '结束时间',
        province: '活动地点',
        area: '活动地点',
        detailed: '活动地点',
        sponsorId: '主办方',
        guestId: '活动嘉宾',
        fileImg: '活动海报',
        tagList: '活动标签',
      }
      for (let i in data) {
        if (!this.isValid(i, data[i], validDir)) return
      }
      if (this.cosponsorId.length) data.cosponsorId = this.cosponsorId
      this.detailsList.forEach((item) => {
        if (item.select) {
          data[keys] = item.value
        }
      })
      data.fileImg = await Basic.uploadImg({ file: this.file })
      Activity.add(data).then(() => {
        this.$router.go(-1)
      })
    },
    isValid(keys, val, validDir) {
      if (validDir[keys] && !val) {
        this.$message.error(`${validDir[keys]}不能为空`)
        return false
      }
      return true
    },
    getGuestList(events) {
      Activity.getGuestList({
        events,
      }).then((res) => {
        this.guestList[events] = res
      })
    },
    async handlerAddPeople() {
      const data = Object.assign({}, this.guestInfo)
      const validDir = {
        name: '名称',
        descript: '简介',
      }
      if (data.events === 3) {
        validDir.job = '职位/头衔'
        validDir.organize = '公司/组织'
      } else {
        validDir.conName = '联系人'
        validDir.conMobile = '联系人手机'
        validDir.conEmail = '联系人邮箱'
      }
      for (let i in data) {
        if (!this.isValid(i, data[i], validDir)) return
      }
      if (data.iconFile.name) {
        data.icon = await Basic.uploadImg({ file: data.iconFile })
        delete data.iconFile
      }
      Activity.addGuest(data).then(() => {
        this.getGuestList(data.events)
        this.dialogVisible = false
      })
    },
    handlerClickAddGuest(events) {
      this.guestInfo.events = Number(events)
      this.dialogVisible = true
    },
  },
}
</script>

<style lang="less" scoped>
.container {
  margin: 0 auto 70px;
  padding-bottom: 67px;
  width: 1200px;
  .mark {
    margin-left: 6px;
    font-size: 5px;
    color: #c6091a;
  }
  .section {
    margin-bottom: 20px;
    padding: 38px 0;
    background: #fff;
    .main {
      padding: 0 105px;
    }

    & /deep/ .el-input__inner {
      border-radius: 0;
      background-color: #ebf1f5;
    }
    .title {
      padding: 0 105px 27px;
      border-bottom: 2px solid #fafbfb;
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
      .title_img {
        margin-right: 10px;
        width: 29px;
        height: 29px;
      }
      .tag {
        margin-left: auto;
        margin-top: 6px;
        display: flex;
        .item {
          position: relative;
          padding: 9px 16px;
          margin-right: 5px;
          font-size: 13px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #595757;
          border: 1px solid #f6f6f6;
          cursor: pointer;
          &.active {
            color: #1c9ab1;
            border-color: #c6e6eb;
          }
          .icon {
            position: absolute;
            top: 2px;
            right: 2px;
          }
        }
      }
    }
    .form_item_title {
      margin-top: 24px;
      display: flex;
      align-items: flex-start;
      font-size: 13px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #595757;
    }
    .form_item_input {
      margin-top: 10px;
    }
    .from_item_time {
      position: relative;
      display: inline-block;
      width: 486px;
      & /deep/ .el-input {
        margin-top: 10px;
        width: 100%;
      }

      & /deep/ .el-input--prefix .el-input__inner {
        padding-left: 15px;
      }
      .time_img {
        position: absolute;
        width: 19px;
        height: 20px;
        top: 58px;
        right: 16px;
      }
    }
    .from_item_select {
      position: relative;
      margin-top: 10px;
      display: flex;
      align-items: center;
      & /deep/ .el-select {
        flex-shrink: 0;
        width: 232px;
      }
      & /deep/ .address_img {
        position: absolute;
        top: 12px;
        right: 16px;
        width: 14px;
        height: 20px;
      }
    }
    .online_event {
      margin-top: 10px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #1c9ab1;
      .online_img {
        margin-right: 4px;
        width: 14px;
        height: 13px;
      }
    }
    .form_item_select {
      margin-top: 10px;
      display: flex;
      & /deep/ .el-select {
        flex: 1;
      }
      .addOption {
        padding: 14px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 192px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #afafaf;
        background-color: #ebf1f5;
        cursor: pointer;
        .img {
          margin-right: 5px;
          width: 12px;
          height: 12px;
        }
      }
    }
    .banner {
      margin-top: 10px;
      display: flex;
      .left {
        width: 483px;
        padding: 38px 90px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #999999;
        line-height: 18px;
        text-align: center;
        background: #f6f7f7;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        .img {
          margin-bottom: 24px;
          width: 76px;
          height: 68px;
        }
      }
      .right {
        margin-left: 27px;
        flex-shrink: 0;
        width: 480px;
        .btn {
          padding: 9px 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 250px;
          font-size: 13px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #eeffff;
          background: #1c9ab1;
          .img {
            margin-right: 10px;
            width: 25px;
            height: 22px;
          }
        }
        .tips {
          margin-top: 70px;
          display: block;
          font-size: 13px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #afafaf;
          line-height: 24px;
        }
      }
    }
    .editor {
      margin-top: 30px;
      & /deep/ .ql-editor {
        height: 186px;
      }
    }
    .tag_container {
      position: relative;
      margin-top: 10px;
      display: flex;
      align-items: center;
      .tag_gorup {
        padding: 15px;
        display: flex;
        align-items: center;
        background: #ebf1f5;
        .item {
          margin-right: 12px;
          padding: 0 16px;
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          letter-spacing: 2px;
          color: #595757;
        }
        .add {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #1c9ab1;
          cursor: pointer;
        }
      }
      .text {
        margin-left: 12px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #afafaf;
      }
      .float_tag_container {
        position: absolute;
        top: 42px;
        left: 0;
        padding: 26px 30px;
        width: 450px;
        height: 350px;
        background: #fff;
        box-shadow: 1px 2px 3px 0 rgba(000, 000, 000, 0.3);
        z-index: 4;
        .container_title {
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #afafaf;
        }
        & /deep/ .el-menu {
          margin-top: 10px;
        }
        & /deep/ .el-menu--horizontal > .el-menu-item {
          height: 40px;
          line-height: 40px;
        }
        & /deep/ .el-menu--horizontal > .el-menu-item.is-active {
          color: #1c9ab1;
          border-color: #1c9ab1;
        }
        .infinite-list {
          margin-top: 18px;
          display: flex;
          flex-wrap: wrap;
          max-height: 200px;
          .infinite-list-item {
            padding: 10px 16px;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            letter-spacing: 2px;
            color: #595757;
            background: #ebf1f5;
            cursor: pointer;
            margin-bottom: 5px;
            margin-right: 5px;
          }
        }
      }
    }
    .tips {
      margin-top: 10px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #afafaf;
      & /deep/ .el-radio-group {
        margin-left: 24px;
      }
      & /deep/ .el-radio__input.is-checked .el-radio__inner {
        border-color: #595756;
        background: #595756;
      }
      & /deep/ .el-radio__inner:hover {
        border-color: #595756;
      }
      & /deep/ .el-radio__input.is-checked + .el-radio__label {
        color: #666;
      }
    }
    .last {
      margin-top: 10px;
      display: flex;
      align-items: center;
      .last_title {
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }

      .save_btn {
        margin-left: auto;
        padding: 10px 16px;
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #eeffff;
        background: #1c9ab1;
      }
    }
    .switch {
      margin-left: 4px;
      display: inline-block;
      width: 76px;
      height: 23px;
      border-radius: 23px;
      background: #ebf1f5;
      .item {
        width: 55px;
        height: 23px;
        line-height: 23px;
        border-radius: 23px;
        text-align: center;
        font-size: 11px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #ffffff;
        background: #afafaf;
        cursor: pointer;
        transition: all 0.25s;
        &.active {
          transform: translateX(50%);
          background: #1c9ab1;
        }
      }
    }
    .apply_container {
      margin-top: 17px;
      padding: 16px 6px;
      background: #fff;
      border: 8px solid #f5f7f8;
      .label {
        display: inline-flex;
        width: 100px;
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
      }
      .row {
        &:not(:first-child) {
          margin-top: 10px;
        }
        & /deep/ .el-input {
          width: 130px;
        }
      }
    }
    .form_container {
      margin-top: 10px;
      padding: 20px 28px;
      background: #f5f7f8;
      .form_row {
        padding: 9px 18px;
        display: flex;
        align-items: baseline;
        border: 1px solid #dfe1e1;
        .form_row_icon {
          margin-right: 5px;

          & /deep/ .el-image__inner {
            line-height: 16px;
            vertical-align: middle;
          }
        }
        .form_row_tip {
          flex-basis: 1;
          margin: 0 24px 0 6px;
          color: #afafaf;
          font-weight: 500;
          font-size: 12px;
        }
        & /deep/ .el-checkbox {
          margin-right: 24px;
        }
        & /deep/ .el-checkbox__input.is_focus .el-checkbox__inner {
          border-color: #b7b8b8;
        }
        & /deep/ .el-checkbox__inner:hover {
          border-color: #b7b8b8;
        }
        & /deep/ .el-checkbox__inner::after {
          border-color: #595757;
        }
        & /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
          background-color: #d5d6d6;
          border-color: #b7b8b8;
        }
        & /deep/ .el-checkbox__input.is-checked + .el-checkbox__label,
        & /deep/ .el-checkbox__label {
          color: #afafaf;
          font-size: 12px;
        }
        & /deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
          border-color: #b7b8b8;
        }
        & /deep/ .el-input__inner {
          width: 525px;
          background: #fff;
        }
        & /deep/ .el-input {
          width: auto;
        }
      }
    }
  }
  .footer_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn {
      padding: 10px 46px;
      border: 1px solid #e4e5e6;

      font-size: 13px;
      font-family: PingFangSC;
      font-weight: 400;
      color: #808080;
      &:last-child {
        margin-left: 30px;
        color: #fff;
        border-color: #1c9ab1;
        background: #1c9ab1;
      }
    }
  }
  .footer_read {
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-family: PingFangSC;
    font-weight: 400;
    color: #808080;
    & /deep/ .el-checkbox__input.is_focus .el-checkbox__inner {
      border-color: #1c9ab1;
    }
    & /deep/ .el-checkbox__inner:hover {
      border-color: #1c9ab1;
    }
    & /deep/ .el-checkbox__inner::after {
      border-color: #fff;
    }
    & /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #1c9ab1;
      border-color: #1c9ab1;
    }
    & /deep/ .el-checkbox__input.is-checked + .el-checkbox__label,
    & /deep/ .el-checkbox__label {
      color: #808080;
      font-size: 12px;
    }
    & /deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
      border-color: #1c9ab1;
    }
    span {
      color: #1c9ab1;
    }
  }
  .create_container {
    padding: 33px 68px 44px;
    .row {
      display: flex;
      align-items: center;
      &:first-child {
        align-items: flex-start;
        .label,
        .mark {
          margin-top: 25px;
        }
        .info {
          margin-top: 13px;
        }
      }
      &:nth-last-child(3) {
        align-items: flex-start;
        .label,
        .mark {
          margin-top: 25px;
        }
      }
      &:last-child {
        & /deep/ .el-input {
          margin-left: 8px;
        }
        & /deep/ .el-input__inner {
          height: 32px;
          line-height: 32px;
        }
      }
      &:not(:first-child) {
        margin-top: 12px;
      }
      .label {
        flex-shrink: 0;
        min-width: 84px;
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
        text-align: right;
      }
      .create_img {
        flex-shrink: 0;
        margin-left: 17px;
        width: 124px;
        height: 124px;
      }
      .info {
        margin-left: 14px;
        .btn {
          padding: 9px 31px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #595757;
          border: 1px solid #eeeeee;
          .btn_icon {
            margin-right: 4px;
            padding: 2px;
            display: flex;
            font-size: 12px;
            font-family: PingFangSC;
            font-weight: 400;
            color: #1c9ab1;
            border: 1px solid #71c0ce;
          }
        }
        .tips {
          margin-top: 18px;
          font-size: 12px;
          font-family: PingFangSC;
          font-weight: 400;
          color: #afafaf;
        }
      }
      & /deep/ .el-input,
      & /deep/ .el-textarea {
        margin-left: 16px;
      }
      & /deep/ .el-input__inner {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #afafaf;
        border-color: #eeeeee;
        border-radius: 2px;
      }
      & /deep/ .el-textarea__inner {
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        line-height: 18px;
        color: #afafaf;
        border-color: #eeeeee;
        border-radius: 2px;
      }
      .pre {
        flex-shrink: 0;
        margin-left: 24px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #afafaf;
      }
      .create_btn {
        flex-shrink: 0;
        margin-left: 10px;
        padding: 9px 19px;
        font-size: 12px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #afafaf;
        background: #ebf1f5;
        border: 1px solid #eeeeee;
      }
    }
    .btn_group {
      margin-top: 32px;
      display: flex;
      justify-content: center;
      .btn {
        padding: 10px 52px;
        font-size: 13px;
        font-family: PingFangSC;
        font-weight: 400;
        color: #595757;
        border: 1px solid #dedede;
        &:last-child {
          margin-left: 12px;
          color: #eeffff;
          background: #1c9ab1;
          border-color: #1c9ab1;
        }
      }
    }
  }
}
</style>